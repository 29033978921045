import React from "react";

export class Pagination extends React.Component {
  render() {
    return (
      <div>
        <div className="row m-0">
          <div className="short_wraping">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12  col-sm-6">&nbsp;</div>
              <div className="col-lg-6 col-md-6 col-sm-12 order-lg-3 order-md-2 col-sm-6">
                <div className="shorting-right">
                  <a href="javascript:void(0);" onClick={() => this.props.prevPage(this.props.pagination.prev_page_url)}>Prev</a>&nbsp;
                  <label className="mx-2">Nombre d'enregistrements ({this.props.pagination.total_records})</label>&nbsp;
                  <a href="javascript:void(0);" onClick={() => this.props.nextPage(this.props.pagination.next_page_url)
                  }>Next</a>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
