import React from "react";

export default class Neighbourhood extends React.Component {
  render() {
    return (
      <div className="card card-body mb-2">
        <h6>{this.props.address.neighbourhood}</h6>
        <p>
          {this.props.address.city}{" "}
        </p>
        <p>
          {this.props.address.metro}{" "}
        </p>
        <p>{this.props.address.state}</p>
      </div>
    );
  }
}
