import React from "react";
import axios from "axios";

import * as Config from "../../helpers/config";

export default class VerifyAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      number: "",
      address: "",
      state: "",
      city: "",
      errors: {
        address: "",
        state: "",
        city: "",
      },
      message: "",
      address_string:"",
    };
  }

  handleOnClick = () => {
    let searchBy = {
      number: this.state.number,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
    };
    axios
      .get(Config.ApiHost + "/api/address/check-address", {
        params: searchBy,
      })
      .then((res) => {
       
        this.setState({
          message: res.data.message,
          address_string:res.data.address_string,
        });
        console.log(this.state);
      })
      .catch((err) => {
        this.setState({
          errors: {
            address: !this.state.address
              ? "Veuillez saisir le numéro ou l'adresse"
              : "",
            state: !this.state.state ? "Veuillez entrer la province" : "",
            city: !this.state.state ? "Veuillez entrer la ville" : "",
          },
        });
      });
  };

  handleChangeText = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: "",
      },
    });
  };

  render() {
    return (
      <div
        className={this.props.isActive ? "tab-pane active" : "tab-pane"}
        id="verify-address"
        role="tabpanel"
        aria-labelledby="verify-address-tab"
      >
        <div>
          <div className="form-group row">
            <label htmlFor="address" className="col-sm-2 col-form-label">
              Numero
            </label>
            <div className="col-sm-10">
              <input
                name="number"
                type="text"
                className="form-control"
                value={this.state.number}
                onChange={this.handleChangeText}
              />
              <p>{this.state.errors.address} </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="address" className="col-sm-2 col-form-label">
              Addresse (Rue, Avenue...)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                name="address"
                className="form-control"
                value={this.state.address}
                onChange={this.handleChangeText}
              />
              <p>{this.state.errors.address} </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="address" className="col-sm-2 col-form-label">
              Commune
            </label>
            <div className="col-sm-10">
              <input
                name="city"
                type="text"
                className="form-control"
                value={this.state.city}
                onChange={this.handleChangeText}
              />
              <p>{this.state.errors.city} </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="state" className="col-sm-2 col-form-label">
              Province
            </label>
            <div className="col-sm-10">
              <input
                name="state"
                type="text"
                className="form-control"
                value={this.state.state}
                onChange={this.handleChangeText}
              />
              <p>{this.state.errors.state} </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
          <button className="btn btn-primary float-right" onClick={this.handleOnClick}>
            Verifier
          </button>
          </div>
          </div>
        </div>

        <div className="clearFix mt-2"></div>
        {this.state.message ? (
          <div className="card card-body" v-if="message">
            <p>{this.state.address_string}</p>
            <p>{this.state.message}</p>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
