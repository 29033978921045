import React from "react";
import axios from "axios";

import ApiService from "../../services/ApiService";
import * as Config from "../../helpers/config";
import Pincodes from "./Pincodes";
import { Pagination } from "../layouts/Pagination";

export default class SearchByCity extends React.Component {
  apiService = new ApiService();

  constructor(props) {
    super(props);

    this.state = {
      addresses: null,
      pagination: {
        current_page: "",
        last_page: "",
        next_page_url: "",
        prev_page_url: "",
        total_records: 0,
      },
      city: "",
      state: "",
      searchBy: {},
      cityError: "",
      stateError: "",
      message: "",
    };
  }

  handleOnClick = () => {
    this.setState(
      {
        searchBy: { city: this.state.city, state: this.state.state },
      },
      () => {
        this.fetchAddresses("");
      }
    );
  };

  fetchAddresses = (pageUrl) => {
    if (this.validate()) {
      pageUrl = pageUrl || Config.ApiHost + "/api/address/search-by-city";

      axios
        .get(pageUrl, {
          params: this.state.searchBy,
        })
        .then((res) => {
          let addresses = null;
          let message = null;
          let pagination = {
            current_page: "",
            last_page: "",
            next_page_url: "",
            prev_page_url: "",
            total_records: 0,
          };
          if (res.data.data.length > 0) {
            addresses = res.data.data.map((address, index) => {
              return (
                <div key={address.id} className="col-sm-3">
                  <Pincodes address={address} />
                </div>
              );
            });

            pagination = {
              current_page: res.data.meta.current_page,
              last_page: res.data.meta.last_page,
              next_page_url: res.data.links.next,
              prev_page_url: res.data.links.prev,
              total_records: res.data.meta.total,
            };
          } else {
            message = (
              <div className="card card-body" v-if="message">
                <p>{res.data.message}</p>
              </div>
            );
          }

          this.setState({
            addresses: addresses,
            message: message,
            pagination: pagination,
          });
        })
        .catch((err) => {
          this.setState({
            cityError: !this.state.city ? "Veuillez entrer la ville" : "",
            stateError: !this.state.state ? "Veuillez entrer la province" : "",
          });
        });
    }
  };

  makePagination(data) {
    this.setState(data);
  }

  handleChangeText = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      [`${event.target.name}Error`]: "",
    });
  };

  validate = () => {
    let flag = true;
    this.setState({
      cityError: "",
      stateError: "",
    });

    if (!this.state.city) {
      this.setState({
        cityError: "Veuillez entrer la ville",
      });

      flag = false;
    }

    if (!this.state.state) {
      this.setState({
        stateError: "Veuillez entrer la province",
      });

      flag = false;
    }

    return flag;
  };

  render() {
    return (
      <div
        className={this.props.isActive ? "tab-pane active" : "tab-pane"}
        id="search-by-city"
        role="tabpanel"
        aria-labelledby="search-by-city-tab"
      >
        <div>
          <div className="form-group row">
            <label htmlFor="city" className="col-sm-2 col-form-label">
              Commune/Territoire
            </label>
            <div className="col-sm-10">
              <input
                name="city"
                type="text"
                className="form-control"
                value={this.state.city}
                onChange={this.handleChangeText}
              />
              <p> {this.state.cityError} </p>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="state" className="col-sm-2 col-form-label">
              Province
            </label>
            <div className="col-sm-10">
              <input
                name="state"
                type="text"
                value={this.state.state}
                className="form-control"
                onChange={this.handleChangeText}
              />
              <p> {this.state.stateError} </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
          <button className="btn btn-primary float-right" onClick={this.handleOnClick}>
            Rechercher le Code Postal
          </button>
          </div>
          </div>
        </div>
        <div className="clearFix mt-2"></div>
        {this.state.addresses ? (
          <div className="col-md-12">
            {
              
              <Pagination
              pagination={this.state.pagination}
              nextPage={this.fetchAddresses}
              prevPage={this.fetchAddresses}
            />
               
            }            
            
            <div className="row">{this.state.addresses}</div>
          </div>
        ) : (
          <div>{this.state.message}</div>
        )}
      </div>
    );
  }
}
