import React from "react";
import SearchByCity from "./searches/SearchByCity";
import SearchByPostalCode from "./searches/SearchByPostalCode";
import VerifyAddress from "./searches/VerifyAddress";
import ListCities  from "./searches/ListCities";

export default class SearchAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "search-by-city",
    };
  }

  handleTabChange = (tab) => {
    let activeTab = {
      activeTab: tab,
    };
    this.setState(activeTab);
  };

  render() {
    return (
      <div>
        <div className="simple_tab_search center">          
          <ul className="nav nav-tabs mb-3" id="myTab" role="tablist">
            <li className="nav-item">
              <a 
                className={
                  this.state.activeTab === "search-by-city"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => this.handleTabChange("search-by-city")}
                id="search-by-city-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="search-by-city"
                aria-selected="false"
              >
                Code Postal par commune/territoire et province
              </a>
            </li>
            <li className="nav-item">
              <a 
                className={
                  this.state.activeTab === "search-by-postal-code"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => this.handleTabChange("search-by-postal-code")}
                id="search-by-postal-code-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="search-by-postal-code"
                aria-selected="false"
              >
                Ville par Code Postal
              </a>
            </li>
           { /* <li className="nav-item">
            <a className={
                  this.state.activeTab === "verify-address"
                    ? "nav-link active"
                    : "nav-link"
                } id="verify-address-tab" data-toggle="tab" role="tab" aria-controls="verify-address" aria-selected="false" onClick={() => this.handleTabChange("verify-address")}>Verifier I'addresses</a>
            </li> */ }
            <li className="nav-item">
              <a 
                className={
                  this.state.activeTab === "list-cities"
                    ? "nav-link active"
                    : "nav-link"
                }
                onClick={() => this.handleTabChange("list-cities")}
                id="list-cities-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="list-cities"
                aria-selected="false"
              >
                Code Postal
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <SearchByCity
              isActive={
                this.state.activeTab === "search-by-city" ? true : false
              }
            />
            <SearchByPostalCode
              isActive={
                this.state.activeTab === "search-by-postal-code" ? true : false
              }
            />
            <VerifyAddress
              isActive={
                this.state.activeTab === "verify-address" ? true : false
              }
            />
            <ListCities
              isActive={
                this.state.activeTab === "list-cities" ? true : false
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
