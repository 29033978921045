import React from "react";

export default class Pincodes extends React.Component {
  render() {
    return (
      <div className="card card-body mb-2">
        <p>Quartier/Secteur/Chefferie: {this.props.address.neighbourhood}</p>
        <p>Commune/Territoire:  {this.props.address.city}</p>
        {this.props.address.metro ? (<p>{'Ville: '+ this.props.address.metro}</p>) : ''} 
        <p>{this.props.address.postalCode} {this.props.address.state}</p>
        Republique democratique du Congo
      </div>
    );
  }
}
