import React from "react";
import axios from "axios";

import * as Config from "../../helpers/config";
import Neighbourhood from "./Neighbourhood";
import { Pagination } from "../layouts/Pagination";

export default class ListCities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: null,
      pagination: {
        current_page: "",
        last_page: "",
        next_page_url: "",
        prev_page_url: "",
        total_records: 0,
      },
      postalCode: "",
      state: "",
      postalCodeError: "",
      stateError: "",
      searchBy: {},
      message: "",
    };
  }

  handleOnClick = () => {
    this.setState(
      {
        searchBy: {
          postalCode: this.state.postalCode,
        },
      },
      () => {
        this.fetchAddresses("");
      }
    );
  };

  fetchAddresses = (pageUrl) => {
    pageUrl = pageUrl || Config.ApiHost + "/api/address/list-by-postal-code";

    axios
      .get(pageUrl, {
        params: this.state.searchBy,
      })
      .then((res) => {
        let addresses = "";
        let message = "";
        let pagination = {
          current_page: "",
          last_page: "",
          next_page_url: "",
          prev_page_url: "",
          total_records: 0,
        };
        if (res.data.data.length > 0) {
          addresses = res.data.data.map((address, index) => {
            return (
              <div key={address.id} className="col-sm-3">
                <Neighbourhood address={address} />
              </div>
            );
          });
          pagination = {
            current_page: res.data.meta.current_page,
            last_page: res.data.meta.last_page,
            next_page_url: res.data.links.next,
            prev_page_url: res.data.links.prev,
            total_records: res.data.meta.total,
          };
        } else {
          message = (
            <div className="card card-body" v-if="message">
              <p>{res.data.message}</p>
            </div>
          );
        }

        this.setState({
          addresses: addresses,
          message: message,
          pagination: pagination,
        });
      })
      .catch((err) => {
        this.setState({
          postalCodeError: !this.state.postalCode
            ? "Veuillez entrer le code postal"
            : "",
          stateError: !this.state.state ? "Veuillez entrer la province" : "",
        });
      });
  };

  handleChangeText = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      [`${event.target.name}Error`]: "",
    });
  };

  render() {
    return (
      <div
        className={this.props.isActive ? "tab-pane active" : "tab-pane"}
        id="list-by-postal-code"
        role="tabpanel"
        aria-labelledby="list-by-postal-code-tab"
      >
        <div>
          <div className="form-group row">
            <label htmlFor="postalCode" className="col-sm-2 col-form-label">
              Code Postal
            </label>
            <div className="col-sm-10">
              <input
                name="postalCode"
                type="text"
                className="form-control"
                value={this.state.postalCode}
                onChange={this.handleChangeText}
              />
              <p>{this.state.postalCodeError}</p>
            </div>
          </div>   
          <div className="row">
              <div className="col-md-12">
          <button className="btn btn-primary float-right" onClick={this.handleOnClick}>
            Rechercher la ville
          </button>
          </div>
            </div>       
        </div>
        <div className="clearFix mt-2"></div>
        {this.state.addresses ? (
          <div>
            {
              
            <Pagination
              pagination={this.state.pagination}
              nextPage={this.fetchAddresses}
              prevPage={this.fetchAddresses}
            />
              
            }
            <div className="row">{this.state.addresses}</div>
          </div>
        ) : (
          <div>{this.state.message}</div>
        )}
      </div>
    );
  }
}
