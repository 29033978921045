import React from "react";
import SearchAddress from "./components/SearchAddress";

function App() {
  return (
    <React.Fragment>      
      <div className="container">
        <SearchAddress />
      </div>
    </React.Fragment>
  );
}

export default App;
